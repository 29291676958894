import React from 'react'

const AuthLayout = ({ children }) => {
  return (
    <div className="container container__auth">
      <div className="auth__wrapper" style={{ minHeight: '100vh' }}>
        {children}
      </div>
    </div>
  )
}

export default AuthLayout
