import React, { useState } from 'react'

import styles from './styles.module.scss'
import menuItems from '../../../data/side-menu'
import { Link } from 'react-router-dom'

const Sidebar = () => {
  const [active, setActive] = useState(null)

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebar__wrapper}>
        <ul>
          {menuItems.map((item) => {
            return (
              <li key={item.id}>
                <Link
                  to={item.path}
                  onClick={(e) => {
                    if (item.childs && item.childs.length > 0) {
                      e.preventDefault()
                      if (active === item.id) {
                        setActive(null)
                      } else {
                        setActive(item.id)
                      }
                    }
                  }}
                >
                  <div className={styles.icon}>{item.icon}</div>
                  <div className={styles.name}>
                    {item.name} {item.badge && <span>{item.badge}</span>}
                  </div>
                  {item.childs && item.childs.length > 0 ? (
                    <div
                      className={`${styles.arrow} ${active === item.id ? styles.arrow__active : ''}`}
                    >
                      <button onClick={() => setActive(item.id)}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.6 4L9.6 12L17.6 20H13.6L6.3 12.7C5.9 12.3 5.9 11.7 6.3 11.3L13.6 4H17.6Z"
                            fill="#3b71fe"
                          />
                        </svg>
                      </button>
                    </div>
                  ) : null}
                </Link>
                {item.childs && (
                  <ul
                    className={
                      active && active === item.id ? styles.active : ''
                    }
                  >
                    {item.childs.map((child) => {
                      return (
                        <li key={child.path}>
                          <Link to={child.path}>{child.name}</Link>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
