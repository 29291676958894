import React, { useContext } from 'react'
import { PiMoon, PiSunBold } from 'react-icons/pi'
import { Button } from 'antd'

import { ThemeContext } from '../../../context/ThemeProvider'

import styles from './styles.module.scss'

const Header = () => {
  const { theme, toggleTheme } = useContext(ThemeContext)

  return (
    <header className={styles.header}>
      <div className={styles.headerWrapper}>
        <div className={styles.leftPart}>
          <div className={styles.logo}>Supplierr</div>
        </div>
        <div className={styles.rightPart}>
          <div className={styles.themeBtn}>
            <Button
              shape="round"
              onClick={() => {
                toggleTheme(theme === 'light' ? 'dark' : 'light')
              }}
              icon={
                theme === 'light' ? (
                  <PiMoon size={18} />
                ) : (
                  <PiSunBold size={18} />
                )
              }
            />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
