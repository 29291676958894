import React from 'react'

import styles from './main-layout.module.scss'
import Header from '../components/layout/header'
import Sidebar from '../components/layout/sidebar'

const AuthLayout = ({ children }) => {
  return (
    <>
      <Header />
      <div className={styles.personalLayout}>
        <Sidebar />
        <div className={styles.personalLayout__wrapper}>
          <main className={styles.personalLayoutContent}>
            <div className={styles.container} style={{ minHeight: '100vh' }}>
              {children}
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default AuthLayout
