import { all, takeEvery, put, call } from 'redux-saga/effects'
import { message } from 'antd'
import { push } from 'redux-first-history'

import axiosRequest from '../../utils/axios/axios-request.util'
import actions from './actions'
import localStorageHelper from '../../utils/local-storage.util'
import axiosErrorUtil from '../../utils/axios/axios-error.util'
import { history } from '../../index'

export function* LOGIN({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { loading: true } })
    yield call(axiosRequest, 'sanctum/csrf-cookie', 'get', null, false)
    const login = yield call(
      axiosRequest,
      'api/auth/login?asAdmin=1',
      'post',
      payload,
    )
    if (login.data.success && login.data.token) {
      if (login.data && login.data.user && login.data.user.is_admin === true) {
        localStorageHelper.set('apiToken', login.data.token)
        message.success(`Welcome, ${login.data.user.name}!`)
        yield put({
          type: actions.SET_STATE,
          payload: {
            user: login.data.user,
            authorized: true,
            loading: false,
            isAdmin: login.data.user.is_admin,
          },
        })
        yield put(push('/'))
      } else {
        message.error('Access Denied')
      }
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false, authorized: false },
      })
    }
  } catch (e) {
    axiosErrorUtil(e)
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false, authorized: false },
    })
  }
}

export function* AUTH_CHECK() {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true, initialStateLoading: true },
  })
  let authorized = false
  const token = localStorageHelper.get('apiToken')
  try {
    if (!token) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          authorized: false,
          initialStateLoading: false,
          subscription: null,
        },
      })
      yield put(push('/auth/login'))
    } else {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: true,
          dataLoading: true,
          initialStateLoading: true,
          subscription: null,
        },
      })
      const authCheck = yield call(
        axiosRequest,
        'api/users/me',
        'get',
        null,
        true,
      )
      if (authCheck.data && authCheck.data.success) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
            dataLoading: false,
            authorized: true,
            user: authCheck.data.user,
            isAdmin: authCheck.data.user.is_admin,
            initialStateLoading: false,
          },
        })
        authorized = true
        if (/^\/secure(?=\/|$)/i.test(history.location.pathname)) {
          yield put(push('/'))
        }
      }
    }
  } catch (e) {
    axiosErrorUtil(e)
    yield put({
      type: actions.SET_STATE,
      payload: {
        user: {},
        loading: false,
        dataLoading: false,
        authorized: false,
        initialStateLoading: false,
      },
    })
    if (!authorized && !/^\/auth(?=\/|$)/i.test(history.location.pathname)) {
      yield put(push('/auth/login'))
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOGIN, LOGIN), AUTH_CHECK()])
}
