import { lazy } from 'react'

const LoginPage = lazy(() => import('../pages/auth/LoginPage'))
const DashboardPage = lazy(() => import('../pages/DashboardPage'))
const CategoriesPage = lazy(() => import('../pages/products/categories/index'))
const ManufacturersPage = lazy(
  () => import('../pages/products/manufacturers/index'),
)
const ProductsPage = lazy(() => import('../pages/products/products/index'))
const LeadsPage = lazy(() => import('../pages/crm/LeadsPage'))
const UsersPage = lazy(() => import('../pages/crm/UsersPage'))

const appRouter = [
  {
    path: '/auth/login',
    Component: LoginPage,
  },
  {
    path: '/',
    Component: DashboardPage,
  },
  {
    path: '/products/categories',
    Component: CategoriesPage,
  },
  {
    path: '/products/manufacturers',
    Component: ManufacturersPage,
  },
  {
    path: '/products/products',
    Component: ProductsPage,
  },
  {
    path: '/crm/users',
    Component: UsersPage,
  },
  {
    path: '/crm/leads',
    Component: LeadsPage,
  },
]

export default appRouter
