import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import AuthLayout from './AuthLayout'
import MainLayout from './MainLayout'

const Layouts = {
  auth: AuthLayout,
  main: MainLayout,
}

const mapStateToProps = ({ users }) => ({ users })

const IndexLayout = ({ users, children, location }) => {
  const { pathname } = location

  const getLayout = () => {
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    return 'main'
  }

  const BootstrappedLayout = () => {
    const Container = Layouts[getLayout()]
    const isUserAuthorized = users.authorized
    // const { user } = users
    // const isAdmin = users.isAdmin
    // const userInfo = users.user
    const userDataLoading = users.dataLoading
    const isAuthLayout = getLayout() === 'auth'
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (userDataLoading) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && !isUserAuthorized) {
      return <Redirect to="/auth/login" />
    }
    if (isAuthLayout && isUserAuthorized) {
      return <Redirect to="/" />
    }
    return <Container>{children}</Container>
  }

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | Supplier">
        <title>Supplier</title>
      </Helmet>
      {BootstrappedLayout()}
    </HelmetProvider>
  )
}

export default withRouter(connect(mapStateToProps)(IndexLayout))
