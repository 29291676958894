const menuItems = [
  {
    id: 1,
    path: '/',
    name: 'Dashboard',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4 6C4 4.89543 4.89543 4 6 4H11V14H4V6Z" fill="#3b71fe" />
        <path
          opacity="0.5"
          d="M13 10H20V18C20 19.1046 19.1046 20 18 20H13V10Z"
          fill="#3b71fe"
        />
        <path
          opacity="0.5"
          d="M13 4H18C19.1046 4 20 4.89543 20 6V8H13V4Z"
          fill="#3b71fe"
        />
        <path
          opacity="0.5"
          d="M4 16H11V20H6C4.89543 20 4 19.1046 4 18V16Z"
          fill="#3b71fe"
        />
      </svg>
    ),
    isMobileOnly: false,
    types: [0, 1],
  },
  {
    id: 2,
    path: '/products/all',
    name: 'Products',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
          fill="#3b71fe"
        />
        <path
          opacity="0.3"
          d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
          fill="#3b71fe"
        />
      </svg>
    ),
    isMobileOnly: false,
    childs: [
      { path: '/products/products', name: 'Products', types: [0] },
      { path: '/products/categories', name: 'Categories', types: [0] },
      { path: '/products/manufacturers', name: 'Manufacturers', types: [0] },
    ],
    types: [0],
  },
  {
    id: 4,
    path: '/crm/leads',
    name: 'CRM',
    icon: (
      <svg
        width="24"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM3.465 13.599C4.077 13.113 5.508 12.6 6.759 12.6C6.822 12.6 6.894 12.609 6.966 12.609C7.182 12.033 7.569 11.448 8.136 10.935C7.632 10.845 7.155 10.791 6.759 10.791C5.589 10.791 3.708 11.196 2.502 12.078C2.052 11.142 1.8 10.098 1.8 8.991C1.8 5.022 5.031 1.791 9 1.791C12.969 1.791 16.2 5.022 16.2 8.991C16.2 10.071 15.957 11.097 15.525 12.024C14.625 11.493 13.401 11.241 12.609 11.241C11.241 11.241 8.559 11.97 8.559 13.671V16.173C6.516 16.056 4.698 15.084 3.465 13.599Z"
          fill="#3b71fe"
        />
        <rect
          opacity="0.5"
          x="4"
          y="5"
          width="5"
          height="5"
          rx="2.5"
          fill="#3b71fe"
        />
        <rect
          opacity="0.5"
          x="11"
          y="7"
          width="3"
          height="3"
          rx="1.5"
          fill="#3b71fe"
        />
      </svg>
    ),
    isMobileOnly: false,
    childs: [
      { path: '/crm/users', name: 'Users', types: [0] },
      { path: '/crm/leads', name: 'Leads', types: [0] },
    ],
    types: [0],
  },
]

export default menuItems
